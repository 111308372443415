import React from 'react';
import { PageProps } from 'gatsby';
import Layout from '@/components/Layout';

const NotFound: React.FC<PageProps> = () => (
  <Layout>
    <p>Sorry, page not found!</p>
  </Layout>
);

export default NotFound;
